import React from "react";
import { Helmet } from "react-helmet";
import "../../Assets/css/privacyPolicy.css";
import { Row, Col, Container } from "react-bootstrap";

const PrivacyPolicy = () => {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Privacy Policy - Belgium Webnet </title>
        <meta name="description" content="Checkout our privacy policy "></meta>
        <meta name="keywords" content=""></meta>
      </Helmet>
      <section className="bw_privacy_policy_section">
        <div className="bw_all_banner bw_banner_pricing">
          <Container className="bw_custome_container">
            <Row className="w-100 m-auto">
              <Col>
                <h1
                  className="bw_banner_heading"
                >
                  Privacy Policy
                </h1>
                <p className="bw_banner_sub_heading">
                  Lorem Ipsum is simply dummy text of the printing and
                  typesetting industry. Lorem Ipsum has been the industry's
                  standard dummy text ever
                </p>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="bw_ppContent_box">
          <Container className="bw_custome_container">
            <Row className="w-100 m-auto">
              <Col>
                <h6>Our Values</h6>
                <p>
                  Trust is the foundation of the Belgium WebNet platform and
                  includes trusting us to do the right thing with your
                  information. Three main values guide us as we develop our
                  products and services. These values should help you better
                  understand how we think about your information and privacy.
                </p>
                <ul>
                  <li>
                    <p>Your information belongs to you</p>
                  </li>
                </ul>
                <p>
                  We carefully analyze what types of information we need to
                  provide our services, and we try to limit the information we
                  collect to only what we really need. Where possible, we delete
                  or anonymize this information when we no longer need it. When
                  building and improving our products, our engineers work
                  closely with our privacy and security teams to build with
                  privacy in mind. In all of this work our guiding principle is
                  that your information belongs to you, and we aim to only use
                  your information to your benefit.
                </p>
                <ul>
                  <li>
                    <p>We protect your information from others</p>
                  </li>
                </ul>
                <p>
                  If a third party requests your personal information, we will
                  refuse to share it unless you give us permission or we are
                  legally required. When we are legally required to share your
                  personal information, we will tell you in advance, unless we
                  are legally forbidden.
                </p>
                <h6>Why we process your information</h6>
                <p>
                  We generally process your information when we need to do so to
                  fulfill a contractual obligation (for example, to process your
                  subscription payments to use the Belgium WebNet platform), or
                  where we or someone we work with needs to use your personal
                  information for a reason related to their business (for
                  example, to provide you with a service). These “legitimate
                  interests” include:
                </p>
                <ul>
                  <li>preventing risk and fraud</li>
                  <li>
                    answering questions or providing other types of support
                  </li>
                  <li>providing and improving our products and services</li>
                  <li>providing reporting and analytics</li>
                  <li>testing out features or additional services</li>
                  <li>
                    assisting with marketing, advertising, or other
                    communications
                  </li>
                </ul>
                <p>
                  We only process personal information for these “legitimate
                  interests” after considering the potential risks to your
                  privacy—for example, by providing clear transparency into our
                  privacy practices, offering you control over your personal
                  information where appropriate, limiting the information we
                  keep, limiting what we do with your information, who we send
                  your information to, how long we keep your information, or the
                  technical measures we use to protect your information.
                </p>
                <h6>Your rights over your information</h6>
                <p>
                  We believe you should be able to access and control your
                  personal information no matter where you live. Depending on
                  how you use Belgium WebNet, you may have the right to request
                  access to, correct, amend, delete, port to another service
                  provider, restrict, or object to certain uses of your personal
                  information (for example, direct marketing). We will not
                  charge you more or provide you with a different level of
                  service if you exercise any of these rights.
                </p>
                <p>
                  If you are not happy with our response to a request, you can
                  contact us to resolve the issue. You also have the right to
                  contact your local data protection or privacy authority at any
                  time.
                </p>
                <h6>How we protect your information</h6>
                <p>
                  Our teams work tirelessly to protect your information, and to
                  ensure the security and integrity of our platform. We also
                  have independent auditors assess the security of our data
                  storage and systems that process financial information.
                  However, we all know that no method of transmission over the
                  Internet, and method of electronic storage, can be 100%
                  secure. This means we cannot guarantee the absolute security
                  of your personal information.
                </p>
              </Col>
            </Row>
          </Container>
        </div>
      </section>
    </>
  );
};

export default React.memo(PrivacyPolicy);
