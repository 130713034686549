import React, { useState, useEffect } from "react";
import { Container, Row, Button, Image } from "react-bootstrap";
import { NavLink, useHistory, useParams } from "react-router-dom";
import "../../Assets/css/header.css";
import main_logo from "../../Assets/images/home/bel_final_white.png";
import bwCirclelogo from "../../Assets/images/home/bw-circle-logo.png";

import BlockLogo from "../../Assets/images/home/logos/main-logo-black.webp";
import { CgMenuRightAlt, CgClose } from "react-icons/cg";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import BwPinkBlueLogo from "../../Assets/images/home/logos/bw-blue-pink-logo.webp";
import { LazyLoadImage } from "react-lazy-load-image-component";
import {
  FaTwitter,
  FaLinkedinIn,
  FaFacebookF,
  FaInstagram,
  FaYoutube
} from "react-icons/fa";
import HomeImage from '../../Assets/images/home/Home.png'

import Accordion from '@material-ui/core/Accordion';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { FiPlus, FiMinus } from 'react-icons/fi'
import FacbookImg from '../../Assets/images/social-img/facebook.png'
import InstagramImg from '../../Assets/images/social-img/instagram.png'
import TwitterImg from '../../Assets/images/social-img/twitter.png'
import LinkedinImg from '../../Assets/images/social-img/linkedin.png'
import YoutubeImg from '../../Assets/images/social-img/youtube.png'
import { BsTelephoneFill } from 'react-icons/bs'
import Topnav from "./Topnav";
import { log } from "three/examples/jsm/nodes/Nodes.js";

const Header = () => {
  const history = useHistory()

  useEffect(() => {
    document.body.className = window.location.pathname;
    return () => { document.body.className = ''; }
  }, [window.location.pathname]);

  const location = useLocation();
  const [isActive, setActive] = useState(false);
  const [hover, setHover] = useState(false);
  const [hover1, setHover1] = useState(false);

  const handleToggle = () => {
    setActive(!isActive);
  };

  useEffect(() => {
    window.addEventListener("scroll", isSticky);
    return () => {
      window.removeEventListener("scroll", isSticky);
    };
  });
  useEffect(() => {
    if (isActive) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflowY = 'scroll';
    }
  }, [isActive])
  const isSticky = (e) => {
    const header = document.querySelector(".bw_header_section");
    const scrollTop = window.scrollY;
    scrollTop >= 250
      ? header.classList.add("is-sticky")
      : header.classList.remove("is-sticky");
  };


  const [expanded, setExpanded] = React.useState(false);
  const [countryName, setCountryName] = React.useState("");

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  useEffect(() => {
    // --------********United kingdom Geolocation data start*********-----------------

    // {"start_ip": "2a02:c7c:2c8a:900::", "end_ip": "2a02:c7c:2c8a:fff:ffff:ffff:ffff:ffff", "join_key": "2a02:c7c::", "city": "London", "region": "England", 
    // "country": "GB", "latitude": "51.50853", "longitude": "-0.12574", "postal_code": "E1W", "timezone": "Europe/London"}

    // --------********United kingdom Geolocation data end*********-----------------

    fetch("https://ipinfo.io/json")
        .then((response) => response.json())
        .then((data) => {
          setCountryName(data?.country)
        })
        .catch((error) => {
          console.error("Error fetching IP information:", error);
        });
  }, [])
  console.log('countryName',countryName);
  return (
    <>
      {/* header drawer menu start */}
       {/* <Topnav />  */}
      <div id="myHeader" className="header-main-d">

        <div className={isActive ? "left-content l-open" : "left-content"}>
          <div className="aside-section aside-left">
            <ul className="bw_first_menu">
              <li>
                <NavLink to="/" onClick={() => setActive(false)}>
                  Home
                </NavLink>
              </li>
              <li>
                <NavLink to="/about-us" onClick={() => setActive(false)}>
                  About Us
                </NavLink>
              </li>
              <li>
                <NavLink to="/services" onClick={() => setActive(false)}>
                  Services
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/diamond-jewelry-designs"
                  onClick={() => setActive(false)}
                >
                  Design Options
                </NavLink>
              </li>
              <li>
                <NavLink to="/integrations" onClick={() => setActive(false)}>
                  Integrations
                </NavLink>
              </li>
              <li>
                <NavLink to="/blogs" onClick={() => setActive(false)}>
                  Blogs
                </NavLink>
              </li>
              <li>
                <NavLink to="/contacts" onClick={() => setActive(false)}>
                  Contact Us
                </NavLink>
              </li>
            </ul>
            <ul className="bw_second_menu">
              <h4 className="text-white text-bold text-uppercase">
                Plans and Pricing
              </h4>
              <li>
                <NavLink
                   to={countryName == "GB" ? "/b2c-pricing-gbp" : "/b2c-pricing"}
                  onClick={() => setActive(false)}
                >
                  B2C Website Development
                </NavLink>
              </li>
              <li>
                <NavLink
                 to={countryName == "GB" ? "/b2b-pricing-gbp" : "/b2b-pricing"}
                  onClick={() => setActive(false)}
                >
                  B2B Website Development
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={countryName == "GB" ? "/shopify-website-pricing-gbp" : "/shopify-website-pricing"}
                  onClick={() => setActive(false)}
                >
                  Shopify Website Development
                </NavLink>
              </li>
              <li>
                <NavLink
                 to={countryName == "GB" ? "/shopify-plugin-pricing-gbp" : "/shopify-plugin-pricing"}
                  onClick={() => setActive(false)}
                >
                  Shopify Plugin
                </NavLink>
              </li>
              {/* <li>
                <NavLink
                  to="/hair-salon-web-development"
                  onClick={() => setActive(false)}
                >
                  Hair Salon Website Development
                </NavLink>
              </li> */}
              <li>
                <NavLink
                  to={countryName == "GB" ? "/plans-pricing-seo-gbp" : "/plans-pricing-seo"}
                  onClick={() => setActive(false)}
                >
                  Search Engine Optimization
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={countryName == "GB" ? "/plans-pricing-smo-gbp" : "/plans-pricing-smo"}
                  onClick={() => setActive(false)}
                >
                  Social Media Marketing
                </NavLink>
              </li>
              <li>
                <NavLink
                 to={countryName == "GB" ? "/email-marketing-gbp" : "/email-marketing"}
                  onClick={() => setActive(false)}
                >
                  Email Marketing
                </NavLink>
              </li>
              <li>
                <NavLink
                  to={countryName == "GB" ? "/listing-and-promotion-of-nventory-gbp" : "/listing-and-promotion-of-nventory"}
                  onClick={() => setActive(false)}
                >
                  Listing & Promotion of Inventory
                </NavLink>
              </li>
            </ul>
            <ul className="bw_second_menu">
              <h4 className="text-white text-bold text-uppercase">
                Design Options
              </h4>
              {/* <li>
                <NavLink
                  to="/diamond-jewelry-designs"
                  onClick={() => setActive(false)}
                >
                  Diamond & Jewelry Designs
                </NavLink>
              </li> */}
              {/* <li>
                <NavLink
                  to="/hair-salon-designs"
                  onClick={() => setActive(false)}
                >
                  Hair Salon Designs
                </NavLink>
              </li> */}
            </ul>
            <ul className="bw_third_menu">
              <h4 className="text-white text-bold text-uppercase">
                EXPERTS IN
              </h4>
              <li>
                <NavLink
                  to="/web-development-design"
                  onClick={() => setActive(false)}
                >
                  Web Design & Developement
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/social-media-marketing"
                  onClick={() => setActive(false)}
                >
                  Social Media Marketing
                </NavLink>
              </li>
              <li>
                <NavLink
                  to="/search-engine-optimization"
                  onClick={() => setActive(false)}
                >
                  Search Engine Optimization
                </NavLink>
              </li>
            </ul>
          </div>
        </div>
        <div className={isActive ? "right-content r-open" : "right-content"}>
          <span className="bw_menu_close_btn" onClick={handleToggle}>
            {" "}
            <CgClose />{" "}
          </span>

          <div className="aside-section aside-right">
            <div className="bw_menu_right_sec bw_menu_desktopview">
              <p>Got An Idea?</p>
              <h2>
                Let's craft <br /> brilliance together!
              </h2>
              <Button
                as={Link}
                to="/contacts"
                className="bw_menu_btn"
                onClick={() => setActive(false)}
              >
                Get In Touch
              </Button>
              <NavLink
                to="/consultation"
                className="bw_book_an_Appointment_headermenu_link"
                onClick={() => setActive(false)}
              >
                <h5>Book An Appointment</h5>
              </NavLink>
              <div className="bw_header_menu_logo">
                <NavLink to="/" onClick={() => setActive(false)}>
                  <LazyLoadImage src={BwPinkBlueLogo} alt="Belgium Webnet" />
                </NavLink>
              </div>
              <div className="d-flex bw_contat_icon_inner bw_header_menu_adderss_box">
                <div>
                  <h6 className="text-uppercase">Address</h6>
                  <a
                    href="https://g.page/belgiumwebnet?share"
                    target="_blank"
                    className="text-decoration-none text-white"
                  >
                    {" "}
                    <p className="bw_footer_address">
                      20 W 47th St, Suite 601 <br />
                      New York, NY 10036
                    </p>
                  </a>
                  <a
                    href="tel:+1 (917) 767-7248"
                    className="text-decoration-none text-white"
                  >
                    {" "}
                    <p className="bw_footer_address">
                      <b>Phone: </b>+1 (917) 767-7248
                    </p>
                  </a>
                  <a
                    href="tel:+1 (646) 992-9024"
                    className="text-decoration-none text-white"
                  >
                    {" "}
                    <p className="bw_footer_address">
                      <b>Phone: </b>+1 (646) 992-9024
                    </p>
                  </a>
                </div>
              </div>
              <div className="bw_footer_link  text-md-left bw_footer_social_icon">
                <h3> FOLLOW US</h3>
                <div className="d-flex justify-content-start btn_outer_box mt-2">
                  <a className="bwn_member_button" href="https://www.instagram.com/belgium_webnet/" target="_blank" ><Image src={InstagramImg} alt="Instagram Image" /></a>
                  <a className="bwn_member_button" href="https://www.facebook.com/belgiumwebnet/" target="_blank" ><Image src={FacbookImg} alt="Facebook Image" /></a>
                  <a className="bwn_member_button" href="https://www.youtube.com/@belgiumwebnet6486" target="_blank" ><Image src={YoutubeImg} alt="Youtube Image" /></a>
                  <a className="bwn_member_button" href="https://www.linkedin.com/company/belgium-webnet/" target="_blank" ><Image src={LinkedinImg} alt="Linkedin Image" /></a>
                </div>
              </div>
            </div>
            {/* Header Mobile View start */}
            <div className="bw_aside_right_mobile_view">
              <div className="bwn_mobile_logo">
                <NavLink to="/" onClick={() => setActive(false)}>
                  <Image src={bwCirclelogo} alt="Belgium Webnet" />
                </NavLink>
              </div>
              <div className="bw_mobile_menu_top">
                <ul className="bw_first_menu">
                  <li>
                    <NavLink to="/about-us" onClick={() => setActive(false)}>
                      About Us
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/services" onClick={() => setActive(false)}>
                      Services
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      to="/integrations"
                      onClick={() => setActive(false)}
                    >
                      Integrations
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/blogs" onClick={() => setActive(false)}>
                      Blogs
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/contacts" onClick={() => setActive(false)}>
                      Contact Us
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/diamond-jewelry-designs" onClick={() => setActive(false)}>
                      Design Options
                    </NavLink>
                  </li>
                </ul>

                <div className="bw_mobile_header_accordian_outer pb-3">
                  {/* <Accordion expanded={expanded === 'panel3'} onChange={handleChange('panel3')} className="p-0 bwn_accordin_main border-top-0">
                    <AccordionSummary
                      expandIcon={expanded === 'panel3' ? <FiMinus /> : <FiPlus />}
                      aria-controls="panel3bh-content"
                      id="panel3bh-header"
                      className="bwn_acco_mb_acc_sum"
                    >
                      <Typography className="bw_mobile_header_price_link_heading">Design Options</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography className="bw_mobile_header_price_link">
                        <ul className="pl-0">
                          <li><NavLink to="/diamond-jewelry-designs" onClick={() => setActive(false)}>Diamond & Jewelry Designs</NavLink></li>
                          <li><NavLink to="/hair-salon-designs" onClick={() => setActive(false)}> Hair Salon Designs</NavLink></li>
                        </ul>
                      </Typography>
                    </AccordionDetails>
                  </Accordion> */}
                  <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')} className="p-0 bwn_accordin_main border-top-0">
                    <AccordionSummary
                      expandIcon={expanded === 'panel1' ? <FiMinus /> : <FiPlus />}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                      className="bwn_acco_mb_acc_sum"
                    >
                      <Typography className="bw_mobile_header_price_link_heading">Plans and Pricing </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography className="bw_mobile_header_price_link">
                        <ul className="pl-0">
                          <li><NavLink to={countryName == "GB" ? "/b2c-pricing-gbp" : "/b2c-pricing"}a onClick={() => setActive(false)}> B2C Website Development</NavLink></li>
                          <li><NavLink to={countryName == "GB" ? "/b2b-pricing-gbp" :"b2b-pricing"} onClick={() => setActive(false)}>  B2B Website Development</NavLink></li>
                          <li><NavLink to={countryName == "GB" ? "/shopify-website-pricing-gbp" : "/shopify-website-pricing"} onClick={() => setActive(false)}>   Shopify Website Development </NavLink></li>
                          <li><NavLink to={countryName == "GB" ? "/shopify-plugin-pricing-gbp" : "/shopify-plugin-pricing"} onClick={() => setActive(false)}>   Shopify Plugin </NavLink></li>
                          {/* <li><NavLink to="/diamond-jewelry-web-development" onClick={() => setActive(false)}> Website Development</NavLink></li> */}
                          {/* <li><NavLink to="/hair-salon-web-development" onClick={() => setActive(false)}>  Hair Salon Website Development</NavLink></li> */}
                          <li><NavLink to={countryName == "GB" ? "/plans-pricing-seo-gbp" : "plans-pricing-seo"} onClick={() => setActive(false)}>Search Engine Optimization</NavLink></li>
                          <li><NavLink to={countryName == "GB" ? "/plans-pricing-smo-gbp" : "plans-pricing-smo"} onClick={() => setActive(false)}>Social Media Marketing</NavLink></li>
                          <li><NavLink to={countryName == "GB" ? "/email-marketing-gbp" : "/email-marketing"} onClick={() => setActive(false)}>  Email Marketing</NavLink></li>
                          <li><NavLink to={countryName == "GB" ? "/listing-and-promotion-of-nventory-gbp" : "/listing-and-promotion-of-nventory"} onClick={() => setActive(false)}>  Listing & Promotion of Inventory</NavLink></li>
                        </ul>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>
                  <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')} className="p-0 bwn_accordin_main border-top-0">
                    <AccordionSummary
                      expandIcon={expanded === 'panel2' ? <FiMinus /> : <FiPlus />}
                      aria-controls="panel2bh-content"
                      id="panel2bh-header"
                      className="bwn_acco_mb_acc_sum"
                    >
                      <Typography className="bw_mobile_header_price_link_heading"> Experts In</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography className="bw_mobile_header_price_link">
                        <ul className="pl-0">
                          <li><NavLink to="/web-development-design" onClick={() => setActive(false)}>Web Design & Developement</NavLink></li>
                          <li><NavLink to="/search-engine-optimization" onClick={() => setActive(false)}>Search Engine Optimization</NavLink></li>
                          <li><NavLink to="/social-media-marketing" onClick={() => setActive(false)}>Social Media Marketing</NavLink></li>
                          <li><NavLink to="/pages/shopify-development" onClick={() => setActive(false)}>Shopify Development</NavLink></li>
                          <li><NavLink to="/pages/email-marketing" onClick={() => setActive(false)}>Email Marketing</NavLink></li>
                          <li><NavLink to="/pages/ppc" onClick={() => setActive(false)}>Paid Advertisement (PPC) </NavLink></li>
                        </ul>
                      </Typography>
                    </AccordionDetails>
                  </Accordion>

                </div>
                <div className="bw_footer_link  text-md-left bw_footer_social_icon">
                  <h3> FOLLOW US</h3>
                  <div className="bwn_mobile_header_social_icon_outer_box">
                    <div className="bwn_footer_social_icon_box_new">
                      <div className="bwn_footer_social_icon_inner_box d-flex">
                        <a className="bwn_footer_iocn_circle bwn_footer_bg_insta" href="https://www.instagram.com/belgium_webnet/" target="_blank">
                          <FaInstagram />
                        </a>
                        <a className="bwn_footer_iocn_circle bwn_footer_bg_facebook" href="https://www.facebook.com/belgiumwebnet/" target="_blank">
                          <FaFacebookF />
                        </a>

                        <a className="bwn_footer_iocn_circle bwn_footer_bg_youtube" href="https://www.youtube.com/@belgiumwebnet6486" target="_blank">
                          <FaYoutube />
                        </a>
                        <a className="bwn_footer_iocn_circle bwn_footer_bg_linkedin" href="https://www.linkedin.com/company/belgium-webnet/" target="_blank">
                          <FaLinkedinIn />
                        </a>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="bwn_header_book_an_appointment_button bw_btb_border_black  ">
                  <Button
                    as={Link}
                    to="/consultation"
                    className="bw_menu_btn_two bw_custom_btn"
                    onClick={() => setActive(false)}
                    style={{ color: '#2c52b0 ' }}
                  >
                    Book An Appointment
                  </Button>
                </div>

              </div>

            </div>
            {/* Header Mobile View end */}
          </div>
        </div>
      </div>
      {/* header drawer menu end */}

      <section id="myHeader2"
        className={
          location.pathname === "/diamond-jewelry-designs" ||
            location.pathname === "/hair-salon-designs" ||
            location.pathname === "/about-us" ||
            location.pathname === "/free-plans-smo" ||
            location.pathname === "/about-us/ashish-jangir" ||
            location.pathname === "/about-us/sami-saghie" ||
            location.pathname === "/services/uk" ||
            location.pathname === "/jangir" ||
            location.pathname === "/sami" ||
            location.pathname === "/thankyou" ||
            location.pathname === "/instagram-pricing" ||
            location.pathname === "/register-instagram-setup" ||


            ((window.location.pathname).includes("/blogs/") && window.location.pathname.length > 7)

            // location.pathname === `/blogs/${location.pathname.slice(7)}`||
            //  location.pathname === "/404"||
            // location.pathname === "*"
            ? "bw_header_section bw_header_other_page"
            : "bw_header_section"
        }
      >
        <Container className="bw_custome_container_header">
          <Row className="m-auto w-100">
            <div className="bw_header">
              <div className="bw_logo" onClick={()=> history.push("/")}>
                <NavLink to="/" onClick={() => setActive(false)}>
                  <LazyLoadImage
                    // src={main_logo}
                    src={bwCirclelogo}
                    
                    alt="Belgium WebNet Logo"
                    className="bw_logo_home"
                  ></LazyLoadImage>
                </NavLink>
              </div>
              <div className="bw_menu">
                <ul className="bw_menu_list">
                  <li>
                    <NavLink to="/services" onClick={() => setActive(false)}>
                      Services
                    </NavLink>
                  </li>
                  <li
                    className="dropdown position-relative"
                    onMouseEnter={() => {
                      setHover1(true);
                    }}
                    onMouseLeave={() => {
                      setHover1(false);
                    }}
                  >
                    <NavLink
                      to="/diamond-jewelry-designs"
                      onMouseEnter={() => {
                        setHover1(false);
                      }}
                      onMouseLeave={() => {
                        setHover1(false);
                      }}
                    >
                      Design Options
                    </NavLink>
                    {/* <div
                      className={
                        hover1
                          ? "bw_header_dropdown-content new_design"
                          : "bw_header_dropdown-content new_design d-none"
                      }
                      onMouseEnter={() => {
                        setHover1(false);
                      }}
                      onMouseLeave={() => {
                        setHover1(false);
                      }}
                    >
                     <div
                        className="bw_extra_dropdown_div"
                        onMouseEnter={() => {
                          setHover1(true);
                        }}
                        onMouseLeave={() => {
                          setHover1(false);
                        }}
                      >
                        {" "}
                        <NavLink to="/diamond-jewelry-designs">
                          Diamond & Jewelry Designs
                        </NavLink>
                        <NavLink to="/hair-salon-designs">
                          Hair Salon Designs
                        </NavLink>

                      </div> 
                    </div> */}
                  </li>
                  <li
                    className="bw_header_dropdown position-relative"
                    onMouseEnter={() => {
                      setHover(true);
                    }}
                    onMouseLeave={() => {
                      setHover(false);
                    }}
                  >
                    <NavLink
                      to={countryName == "GB" ? "/b2c-pricing-gbp" : "/b2c-pricing"}
                      onMouseEnter={() => {
                        setHover(true);
                      }}
                      onMouseLeave={() => {
                        setHover(false);
                      }}
                    >
                      Plans and Pricing
                    </NavLink>
                    <div
                      className={
                        hover
                          ? "bw_header_dropdown-content"
                          : "bw_header_dropdown-content d-none"
                      }
                      onMouseEnter={() => {
                        setHover(true);
                      }}
                      onMouseLeave={() => {
                        setHover(false);
                      }}
                    >
                      <div
                        className="bw_extra_dropdown_div"
                        onMouseEnter={() => {
                          setHover(true);
                        }}
                        onMouseLeave={() => {
                          setHover(false);
                        }}
                      >
                        {" "}
                        <NavLink to={countryName == "GB" ? "/b2c-pricing-gbp" : "/b2c-pricing"}>
                          B2C Website Development
                        </NavLink>
                        <NavLink to={countryName == "GB" ? "/b2b-pricing-gbp" :"b2b-pricing"}>
                          B2B Website Development
                        </NavLink>
                        <NavLink to={countryName == "GB" ? "/shopify-website-pricing-gbp" : "/shopify-website-pricing"}>
                          Shopify Website Development
                        </NavLink>
                        <NavLink to={countryName == "GB" ? "/shopify-plugin-pricing-gbp" : "/shopify-plugin-pricing"}>
                          Shopify Plugin
                        </NavLink>
                        {/* <NavLink to={countryName == "GB" ? "/b2b-pricing-gbp" : "/hair-salon-web-development"}>
                          Hair Salon Website Development
                        </NavLink> */}
                        <NavLink to={countryName == "GB" ? "/plans-pricing-seo-gbp" : "/plans-pricing-seo"}>
                          Search Engine Optimization
                        </NavLink>
                        <NavLink to={countryName == "GB" ? "/plans-pricing-smo-gbp" : "plans-pricing-smo"}>
                          Social Media Marketing
                        </NavLink>
                        <NavLink to={countryName == "GB" ? "/email-marketing-gbp" : "/email-marketing"}>
                          Email Marketing
                        </NavLink>
                        <NavLink to={countryName == "GB" ? "/listing-and-promotion-of-nventory-gbp" : "/listing-and-promotion-of-nventory"}>
                          Listing & Promotion of Inventory
                        </NavLink>
                      </div>
                    </div>
                  </li>
                  <li>
                    <NavLink to="/integrations" onClick={() => setActive(false)}>
                      Integrations
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/blogs" onClick={() => setActive(false)}>
                      Blogs
                    </NavLink>
                  </li>
                  <li>
                    <NavLink to="/about-us" onClick={() => setActive(false)}>
                      About Us
                    </NavLink>
                  </li>
                  <li>
                    <div className="bw_btb_border_white">
                      <Link
                        className="bw_custom_btn"
                        to="/consultation"
                        as={Link}
                      >
                        Book An Appointment
                      </Link>
                    </div>
                  </li>
                  <li>
                    <div className="bw_btb_border_white">
                      <a
                        className="bw_custom_btn"
                        href="tel:+1 9177677248"
                      >
                        <sapn><BsTelephoneFill className="bw_phone_Svg_margin_right" /></sapn>
                        +1 (917) 767-7248
                      </a>
                    </div>
                  </li>
                  <div className="bw_menu_btn_Main">
                    <span className="bw_menu_btn" onClick={handleToggle}>
                      {" "}
                      <CgMenuRightAlt />{" "}
                    </span>
                  </div>
                </ul>
              </div>
            </div>
          </Row>
        </Container>
      </section>
    </>
  );
};

export default React.memo(Header);
