import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import img1 from "../../Assets/images/home/gif/s1.gif";
import img2 from "../../Assets/images/home/gif/s2.gif";
import img3 from "../../Assets/images/home/gif/s3.gif";
import img4 from "../../Assets/images/home/gif/s4.gif";
import img5 from "../../Assets/images/home/gif/s5.gif";
import img6 from "../../Assets/images/home/gif/s6.gif";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LeftIcon from "../../Assets/images/home/left_icon.png";
import RightIcon from "../../Assets/images/home/right_arrow.png";
import { LazyLoadImage } from "react-lazy-load-image-component";

import ServicesImg1 from '../../Assets/images/services-img/webdev.png'
import ServicesImg2 from '../../Assets/images/services-img/ux.png'
import ServicesImg3 from '../../Assets/images/services-img/shopify.png'
import ServicesImg4 from '../../Assets/images/services-img/smm.png'
import ServicesImg5 from '../../Assets/images/services-img/seo.png'
import ServicesImg6 from '../../Assets/images/services-img/email-marketing.png'
import ServicesImg7 from '../../Assets/images/services-img/ppc.png'
import { Link, useHistory } from "react-router-dom";

const NewBandingUk = () => {
  const history = useHistory();
  const newbranding = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    nextArrow: <LazyLoadImage src={RightIcon} alt='Left Icon' />,
    prevArrow: <LazyLoadImage src={LeftIcon} alt='Right Icon' />,
    responsive: [
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      {/* desktop view start */}
      <section className="bw_new_branding_section bw_new_branding_desktop hm_branding_box_main">
        <Container className="bw_custome_container">
          <Row>
            <Col lg={12} xs={12}>
              <div className="bw_new_branding_main_title">
                {/* <div className="bw_new_branding_main_title_dots">
                  <span> </span> <p>our services</p>
                </div> */}
                <h2>Let’s Build A Bigger Brand Together  </h2>
              </div>
            </Col>
          </Row>

          <Row>
            <Col lg={4} sm={6} xs={6}>
              <div
                className="bw_new_branding_box "
              >

                <div className="bw_new_branding_box_icon">
                  <Link to="/web-development-design"><LazyLoadImage src={ServicesImg1} alt="Brand Image" /></Link>
                </div>
                <h2 ><Link to="/web-development-design" className="colorBlack"> Website Development <br></br> & Design</Link></h2>
                <p>
                Obtain user-friendly layouts, attractive
visuals, and engaging content to improve
your digital presence.
                </p>
              </div>
            </Col>
            <Col lg={4} sm={6} xs={6}>
              <div
                className="bw_new_branding_box"
              >
                <div className="bw_new_branding_box_icon">
                  <LazyLoadImage src={ServicesImg3} alt="Brand Image" />
                </div>
                <h2><Link to="/pages/shopify-development" className="colorBlack">Shopify  <br></br> Development</Link></h2>
                <p>
                Optimising online presence for increased
sales and customer engagement
for jewellers.
                </p>
              </div>
            </Col>

         

            <Col lg={4} sm={6} xs={6}>
              <div
                className="bw_new_branding_box"
              >
                <div className="bw_new_branding_box_icon">
                  <Link to="/search-Engine-Optimization">  <LazyLoadImage src={ServicesImg5} alt="Brand Image" /></Link>
                </div>
                <h2><Link to="/search-Engine-Optimization" className="colorBlack"> Search Engine <br></br> Optimization </Link></h2>
                <p>
                Boost your online visibility with targeted
optimisation strategies, reach more
customers, and increase website traffic.
                </p>
              </div>
            </Col>

            <Col lg={4} sm={6} xs={6}>
              <div
                className="bw_new_branding_box"
              >
                <div className="bw_new_branding_box_icon">
                  <Link to="/social-media-marketing"> <LazyLoadImage src={ServicesImg4} alt="Brand Image" /> </Link>
                </div>
                <h2><Link to="/social-media-marketing" className="colorBlack"> Social Media <br></br> Marketing </Link> </h2>
                <p>
                Engage customers, showcase products,
and drive sales with social media
strategies tailored for jewellers.
                </p>
              </div>
            </Col>

            <Col lg={4} sm={6} xs={6}>
              <div
                className="bw_new_branding_box"
              >
                <div className="bw_new_branding_box_icon">
                  <LazyLoadImage src={ServicesImg6} alt="Brand Image" />
                </div>
                <h2><Link to="/pages/email-marketing" className="colorBlack">Email <br></br> Marketing</Link></h2>
                <p>
                Build relationships with your customers,
increase sales, and drive engagement
by using targeted campaigns.
                </p>
              </div>
            </Col>
            <Col lg={4} sm={6} xs={6}>
              <div
                className="bw_new_branding_box"
              >
                <div className="bw_new_branding_box_icon">
                  <Link to="/web-development-design">   <LazyLoadImage src={ServicesImg7} alt="Brand Image" /> </Link>
                </div>
                <h2><Link to="#" className="colorBlack"> PPC <br></br> Marketing </Link> </h2>
                <p>
                Acquire immediate results with targeted
ads at optimised budgets to drive
valuable traffic for your business.
                </p>
              </div>
            </Col>

          </Row>
        </Container>
      </section>
      {/* desktop view end */}

      {/* mobile view start */}
      {/* <section className="bw_new_branding_section bw_new_branding_mobile">
        <Container className="bw_custome_container">
          <Row>
            <Col lg={12} xs={12}>
              <div className="bw_new_branding_main_title">
               
                <h2> Let’s Build A Bigger Brand Together </h2>
              </div>
            </Col>
          </Row>

          <Row>
            <div className="w-100 bw_brand_slider_right_slide_main bw_new_branding_mobile_slider_main">
              <Slider {...newbranding}>
                 <Col>
                  <div
                    className="bw_new_branding_box"
                  >
                    <div className="bw_new_branding_box_icon">
                    <Link to="/web-development-design"> <LazyLoadImage src={ServicesImg1} alt="Brand Image" /></Link>
                    
                    </div>
                    <h2> <Link to="/web-development-design" className="colorBlack"> Website Development </Link></h2>
                    <p>
                      Develop websites that have a global presence with maximum traffic and an attractive web design.
                    </p>
                  </div>
                </Col>
                 <Col>
                  <div
                    className="bw_new_branding_box"
                  >
                    <div className="bw_new_branding_box_icon">
                    <Link to="/web-development-design"> <LazyLoadImage src={ServicesImg2} alt="Brand Image" /> </Link>
                    </div>
                    <h2> <Link to="/web-development-design" className="colorBlack">  Website Designing </Link></h2>
                    <p>
                    Design that provides customers with a relevant experience and a meaningful insight into the product.
                    </p>
                  </div>
                </Col>
                <Col lg={4} sm={6} xs={12}>
                  <div
                    className="bw_new_branding_box"
                  >
                    <div className="bw_new_branding_box_icon">
                      <LazyLoadImage src={ServicesImg3} alt="Brand Image" />
                    </div>
                    <h2>Shopify Development</h2>
                    <p>
                      Achieve Higher Search Ranking by making the website easily accessible to potential customers.
                    </p>
                  </div>
                </Col>

                <Col>
                  <div
                    className="bw_new_branding_box"
                  >
                    <div className="bw_new_branding_box_icon">
                    <Link to="/social-media-marketing">  <LazyLoadImage src={ServicesImg4} alt="Brand Image" /> </Link>
                    </div>
                    <h2> <Link to="/social-media-marketing" className="colorBlack">  Social Media Marketing </Link></h2>
                    <p>
                     Improve relations with your customers with strategies like SMO, CRO, Content Marketing, and much more.
                    </p>
                  </div>
                </Col>

               

                <Col>
                  <div
                    className="bw_new_branding_box"
                  >
                    <div className="bw_new_branding_box_icon">
                    <Link to="/search-Engine-Optimization">  <LazyLoadImage src={ServicesImg5} alt="Brand Image" /> </Link>
                    </div>
                    <h2><Link to="/search-Engine-Optimization" className="colorBlack">  Search Engine Optimization </Link></h2>
                    <p>
                     Create a strong marketing strategy that will convert potential customers into revenue-generating clients.
                    </p>
                  </div>
                </Col>
                <Col>
                  <div
                    className="bw_new_branding_box"
                  >
                    <div className="bw_new_branding_box_icon">
                      <LazyLoadImage src={ServicesImg6} alt="Brand Image" />
                    </div>
                    <h2>Email Marketing</h2>
                    <p>
                     Acquire assistance to construct a detailed and structured roadmap for your business.
                    </p>
                  </div>
                </Col>
               
              </Slider>
            </div>
          </Row>
        </Container>
      </section> */}
      {/* mobile view end */}
    </>
  );
};

export default React.memo(NewBandingUk);
