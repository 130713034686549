import React from 'react'
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import webteam from '../../../Assets/images/about-us/dedecatedteam/webteam.png'
import backendteam from '../../../Assets/images/about-us/dedecatedteam/backendteam.png'
import digigitalmarketing from '../../../Assets/images/about-us/dedecatedteam/digigitalmarketing.png'
import projectteam from '../../../Assets/images/about-us/dedecatedteam/projectteam.png'
import hrteam from '../../../Assets/images/about-us/dedecatedteam/hrteam.png'

const OurDecatedTeam = () => {
  return (
   <section className='bwn_our_dedicated_team'>
<Container className='bw_custome_container'>
<div className='bwn_tabs_our_dedecated'>
    <div className='bwn_title_team_dede'>
        <h3>Our Dedicated Team</h3>
        {/* <p>Lorem Ipsum is simply dummy text of the</p> */}
    </div>
    <div className='bw_iner_teams'>
    <Tab.Container id="left-tabs-example" defaultActiveKey="first">
      <Row>
        <Col sm={12} md={6} lg={6} >
          <Nav variant="pills" className="flex-column">
            <Nav.Item>
              <Nav.Link eventKey="first" class='webteam'>
                <div className='tab_head'> 
                 <h3> Front-End Team </h3>
                </div>
                </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="second">
              <div className='tab_head'> 
                <h3> Back-End Team </h3>
                </div>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="third">
              <div className='tab_head'> 
                <h3> Digital Marketing Team </h3>
                </div>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="fourth">
              <div className='tab_head'> 
                <h3> Project Management Team </h3>
                </div>
              </Nav.Link>
            </Nav.Item>
            <Nav.Item>
              <Nav.Link eventKey="fifth">
              <div className='tab_head'> 
                <h3>  HR Team </h3>
                </div>
              </Nav.Link>
            </Nav.Item>
          </Nav>
        </Col>
        {/* decs of tabs */}
        <Col sm={12} md={6} lg={6}>
          <Tab.Content>
            <Tab.Pane eventKey="first">
                <div className='bw_teams'>
                 <img src={webteam} alt='webteam' className='img-fluid'/>
                </div>
            </Tab.Pane>
            <Tab.Pane eventKey="second">
                <div className='bw_teams'>
                 <img src={backendteam} alt='backendteam' className='img-fluid'/>
                </div>
            </Tab.Pane>
            <Tab.Pane eventKey="third">
                <div className='bw_teams'>
                 <img src={digigitalmarketing} alt='digigitalmarketing' className='img-fluid'/>
                </div>
            </Tab.Pane>
            <Tab.Pane eventKey="fourth">
                <div className='bw_teams'>
                 <img src={projectteam} alt='projectteam' className='img-fluid'/>
                </div>
            </Tab.Pane>
            <Tab.Pane eventKey="fifth">
                <div className='bw_teams'>
                 <img src={hrteam} alt='hrteam' className='img-fluid'/>
                </div>
            </Tab.Pane>
          </Tab.Content>
        </Col>
      </Row>
    </Tab.Container>
    </div>
</div>
</Container>
   </section>
  )
}

export default OurDecatedTeam
