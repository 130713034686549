import React from "react";
import axios from "axios";
import "../../Assets/css/portfolio.css";
import { Container, NavLink, Row, Col } from "react-bootstrap";
import { Helmet } from "react-helmet";
import Slider from "react-slick";
import { baseURL } from "../../Helpers/request";
import { useMediaQuery } from "react-responsive";
import LeftIcon from "../../Assets/images/home/left_icon.png";
import RightIcon from "../../Assets/images/home/right_arrow.png";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import BrandLogoMobileView from "../Front/BrandLogoMobileView";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import { isMobile } from "react-device-detect";
import PortfolioPageMobileView from "./PortfolioPageMobileView";



function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}


const Portfolio = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };
  const [userdata, setUserdata] = React.useState([]);
  const [userdatacate, setUserdatacate] = React.useState([]);
  const [userdatacateactive, setUserdatacateactive] = React.useState('');
  const [c_id, setC_id] = React.useState(1);

  React.useEffect(() => {
    GetUserdata(c_id);
  }, [c_id]);
  React.useEffect(() => {
    GetUserdataCategory();
  }, []);
  const GetUserdata = (id) => {
    axios
      .get(`${baseURL}/home/clients/${id}`)
      .then((res) => {
        if (res.data.status == 1) {
          setUserdata(res.data.data);
        } else {
          console.log(res.data.message);
        }
      })
      .catch((error) => {
        if (error) {
          console.log("something went wrong");
        }
      });
  };
  const GetUserdataCategory = () => {
    axios
      .get(`${baseURL}/Client/getClientsCategory`)
      .then((res) => {
        if (res.data.status == 1) {
          setUserdatacate(res.data.data);
        } else {
          console.log(res.data.message);
        }
      })
      .catch((error) => {
        if (error) {
          console.log("something went wrong");
        }
      });
  };
  //Get Userdata(client) end
  const settingsMobile = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 2,
    autoplay: true,
    autoplaySpeed: 3000,
    cssEase: "linear",
    nextArrow: <LazyLoadImage src={RightIcon} />,
    prevArrow: <LazyLoadImage src={LeftIcon} />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 320,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Belgium Webnet- Grow Your Jewelry Business with Us</title>
        <meta
          name="description"
          content="Our clients are our strength and we make them happy by our services. With exceptional website features like- Ring Builder, Social Feed and more we cop up with changing market trends."
        ></meta>
        <meta name="keywords" content=""></meta>
      </Helmet>
      <div className="bw_all_banner">
        <Container className="bw_custome_container">
          <Row>
            <Col>
              <h1 className="bw_banner_heading">Some of our clients</h1>
              <p className="bw_banner_sub_heading">
                Belgium Webnet is a confidant to many global brands.
              </p>
            </Col>
          </Row>
        </Container>
      </div>
      {/* desktop view of tabs satrt */}
      <div className="_bw_desktop_view_of_tabs bw_portfolio_desktop_tab">
        <Container fluid>
          <Row className="bwn_client_logo_Desktop_View only_portfolio">
            <Col>
              <div className="bwn_our_clint_tab_section_box mt-3 mt-lg-4">

                <Tabs aria-label="basic tabs example" centered className="bwn_our_clint_tab" indicatorColor="secondary">
                  {userdatacate.map((res, index) => (
                    <Tab label={res.name} key={index} {...a11yProps(index)} style={{ borderBottom: userdatacateactive == index ? '2px solid #3D63C3' : '2px solid #0000', color: userdatacateactive == index ? '#3d63c3' : '#001100' }} className="bwn_our_clint_tab_button" onClick={(e) => { setC_id(res.id); setUserdatacateactive(index) }} />))}
                </Tabs>

                <TabPanel value={value} index={0}>
                  <div className="bwn_client_tab_logo">
                    <ul className="bw_client_logo_img_ul w-100 pl-0 mb-0">
                      {userdata.map((client) => (
                        <li>
                          <div className="bw_port_client_img_outerrr_box">
                            <LazyLoadImage
                              src={client.logo}
                              onClick={() =>
                                window.open(`${client.link}`, "_blank")
                              }
                              alt="Client Logo Image"
                            />
                          </div>
                        </li>
                      ))}
                    </ul>
                  </div>
                </TabPanel>

              </div>
            </Col>
          </Row>
        </Container>
      </div>
      {/* desktop view of tabs end */}
      {/* mobile View start */}
      {/* <div className="bw_mobile_view_of_tabs mb-2 mt-2">
        <BrandLogoMobileView />
      </div> */}
      {/* <PortfolioPageMobileView /> */}
      {/* mobile View start */}

    </>
  );
};

export default React.memo(Portfolio);

